import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/K9mkk0sqPB8">
    <SEO title="Digital Parenting - Marriage, Money, and Minions" />
  </Layout>
)

export default SermonPost
